@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Qasbyne:wght@400&display=swap'); */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", "Inter", sans-serif;
}
@font-face {
  font-family: "Qasbyne";
  src: url("../public/fonts/Qasbyne.otf") format("truetype");
}
/* start ag grid style*/
div:where(.swal2-container) div:where(.swal2-timer-progress-bar) {
  background-color: var(--Pr-color) !important;
}
.ag-center-cols-container .my-shaded-effect {
  /* background-color: greenyellow !important; */
  /* opacity: 0; */
}
.ag-center-cols-container .shaded-effect {
  background-color: var(--Pr-color) !important;
  opacity: 0;
}
.ag-theme-alpine {
  --ag-foreground-color: var(--Sec-color);
  --ag-background-color: var(--bg-color) !important;
  --ag-selected-row-background-color: var(--Sec-color) !important;
  --ag-header-foreground-color: var(--Sec-color) !important;
  --ag-header-background-color: var(--Tre-color) !important;
  --ag-icon-size: 20px !important;
  --ag-font-size: 14px !important;
  --ag-list-item-height: 30px !important;
  --ag-icon-font-color: var(--Sec-color) !important;
  --ag-row-height: 60px !important;
}
.ag-theme-alpine .ag-header-cell-filter-button:hover,
.ag-theme-alpine .ag-header-cell-filter-button:focus {
  color: var(--Pr-color) !important;
}
.ag-root-wrapper {
  border: transparent !important;
}
.ag-header-row {
  /* direction: rtl; */
}
.ag-root-wrapper.ag-layout-normal {
  /* direction: ltr; */
}
.ag-header-cell-sortable .ag-header-cell-label {
  justify-content: center !important;
}
.ag-header-cell-label {
  justify-content: center !important;
}
.ag-header-cell-label .ag-header-cell-text {
  font-size: 18px;
  font-weight: normal;
  direction: ltr;
}
.ag-cell {
  display: flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: "100%" !important;
}
.ag-cell-value,
.ag-group-value {
  /* direction: rtl !important; */
}
.ag-cell-wrapper {
  width: 100% !important;
  /* height: 100%; */
}
.ag-header-cell-filtered {
  /* background-color: red !important; */
  /* color: red !important; */
}
.ag-theme-quartz .ag-header-cell {
  font-size: 24px !important;
}
.ag-header-cell-filtered span {
  color: var(--Pr-color) !important;
}
.ag-row-odd {
  background-color: var(--nth-child) !important;
}
.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none !important;
}
.ag-row-hover:not(.ag-full-width-row)::before {
  background-color: transparent !important;
}
.ag-row {
  border-bottom: none !important;
  text-align: center;
  height: 60px !important;
}
.ag-header-cell-filtered span {
  color: var(--Pr-color) !important;
}
.ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: none !important;
}
.ag-row-hover:not(.ag-full-width-row)::before {
  background-color: transparent !important;
}
/* end ag grid style*/

/* Start bg_background */
.bg_loader {
  background: #00000069;
  position: absolute;
  z-index: 12;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg_loader .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.bg_loader .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-left: 4px solid var(--btn-send);
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* End bg_background */

/* Start loader_btn */
/*  start btn_loader */
.btn_loader {
  background-color: var(--Pr-color);
  padding: 15px 20px;
  border-radius: 20px;
  width: 100px;
  margin: auto;
  text-align: center;
}
.btn_loader .loader {
  text-align: center;
  margin: auto;
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}
@keyframes l5 {
  0% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #000;
  }
  33% {
    box-shadow: 20px 0 #000, -20px 0 #0002;
    background: #0002;
  }
  66% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #0002;
  }
  100% {
    box-shadow: 20px 0 #0002, -20px 0 #000;
    background: #000;
  }
}
/* .btn_loader .loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
}
.btn_loader {
  background-color: var(--Sec-color);
  padding: 16px 45px;
  border-radius: 20px;
}
.btn_loader .loader {
  width: 15px;
  aspect-ratio: 1;
  position: relative;
}
.btn_loader .loader::before,
.btn_loader .loader::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: #000;
}
.btn_loader .loader::before {
  box-shadow: -25px 0;
  animation: l8-1 1s infinite linear;
}
.btn_loader .loader::after {
  transform: rotate(0deg) translateX(25px);
  animation: l8-2 1s infinite linear;
} */
/* End loader_btn */
.p-paginator {
  background-color: transparent !important;
}
.p-paginator-element:focus,
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background-color: var(--Tre-color) !important;
  color: var(--Pr-color) !important;
}

@media (max-width: 767px) {
  .imageMedia {
    width: 70% !important;
    margin-top: -50px !important;
  }
}

.Page {
  padding: 10px 25px;
  height: auto;
  min-height: calc(100vh - 60px);
}
.Page main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.Page main .header-left i {
  font-size: 25px;
  background-color: var(--Pr-color);
  border-radius: 50%;
  padding: 10px;
  color: var(--Sec-color);
  cursor: pointer;
}
/* start input search */
.Page .input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
}
.Page .input {
  border-style: none;
  height: 45px;
  width: 45px;
  padding: 16px 4px;
  outline: none;
  border-radius: 50%;
  transition: 0.5s ease-in-out;
  background-color: var(--Pr-color);
  padding-right: 40px;
  color: var(--Pr-color);
}
.Page .input::placeholder,
.Page .input {
  font-size: 18px;
  color: var(--Tre-color);
}
.Page .input::placeholder {
  color: var(--Tre-color);
  opacity: 0.2;
}
.Page .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  outline: none;
  border-style: none;
  border-radius: 50%;
  pointer-events: painted;
  background-color: transparent;
  transition: 0.2s linear;
}
.Page .icon i {
  color: var(--Sec-color);
  font-size: 25px;
  margin-left: 5px;
}
.Page .icon:focus ~ .input,
.Page .input:focus {
  box-shadow: none;
  width: 200px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 2px solid var(--Tre-color);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

/* end input search */

.Page .Actions {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.Page .Actions img {
  width: 60px;
  height: 60px;
}
.Page .Actions i {
  font-size: 20px;
  cursor: pointer;
}
.react-tel-input .form-control,
.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: 2px solid var(--Pr-color) !important;
  height: 48px !important;
  color: var(--Sec-color);
  font-family: "Poppins" !important;
}
.react-tel-input .flag-dropdown:hover {
  background-color: transparent !important;
}
.react-tel-input .flag-dropdown.open,
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus,
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}
.p-checkbox.p-highlight .p-checkbox-box {
  background-color: var(--Pr-color);
  color: var(--Sec-color);
}
.AgGridPer {
  background-color: var(--Tre-color);
  color: var(--Sec-color);
  height: 400px;
}
.AgGridPer main {
  display: none;
}
.Per .p-focus {
  color: var(--Sec-color) !important;
  background-color: var(--Tre-color) !important;
}
.Per .p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none !important;
}
.Per .p-multiselect {
  background-color: var(--Tre-color);
  color: var(--Sec-color);
  border: none;
  padding: 10px 20px !important;
}
.Per .p-float-label:has(.p-inputwrapper-filled) label {
  top: -1.2em !important;
  background-color: var(--bg-color) !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  padding: 10px 20px;
  color: var(--Tre-color);
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item.p-highlight
  .p-focus {
  color: red !important;
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item.p-highlight.p-focus {
  background-color: var(--nth-child) !important;
  color: var(--Tre-color) !important;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  color: var(--Sec-color) !important;
}
.p-dialog-header-icon:hover {
  background-color: brown !important;
  color: var(--Sec-color) !important;
}
.p-tabview {
  width: 100%;
}
.p-tabview .p-tabview-nav {
  background-color: transparent !important;
  border-color: var(--Tre-color) !important;
}
.p-tabview .p-tabview-panels {
  background-color: transparent !important;
  color: var(--Tre-color) !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 10px 12px;
  margin-right: 10px;
  background-color: transparent;
  color: var(--Tre-color);
  border-color: var(--bg-color);
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  padding: 10px 12px;
  /* color: var(--Pr-color) !important; */
  border-color: var(--bg-color);
  background-color: var(--Tre-color);
  color: var(--Pr-color);
}
.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  color: var(--Pr-color) !important;
  border-color: var(--Pr-color);
}

.p-tabview .p-tabview-nav-btn.p-link {
  background-color: var(--Tre-color);
  color: var(--Pr-color);
}
